import "./SignIn.css";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context";
import statsImg from "../../assets/stats.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { image } from "../../constant";

function SignIn() {
  const { setToken, setRole, setUserId } = useContext(MyContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const notifyError = (msg) => toast.error(msg);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    fetch(process.env.REACT_APP_API_URL + "auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData }),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === true) {
          setToken(res.data.accessToken);
          setRole(res.data.role || "");
          setUserId(res.data.id);
          const userDataString = JSON.stringify(res.data);
          localStorage.setItem("userData", userDataString);
          navigate("/dashboard/weekly-reports");
        } else {
          notifyError(res.message);
        }
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  return (
    <div className="min-h-full flex lg:flex-row flex-col-reverse items-center justify-between container m-auto">
      <ToastContainer theme="colored" />
      <div className="p-10">
        <img src={statsImg} alt="stats" width={800} height={600} />
      </div>
      <div className="flex  flex-col justify-center px-6 py-12 lg:px-8 md:h-screen  md:w-1/2 w-full shadow-slate-50 shadow-gray-100	">
        <img
          className="mx-auto h-10 w-auto"
          src={image.RUBICO_SIGNIN_CARD_LOGO}
          alt="Your Company"
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
          Sign in to your account
        </h2>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={onSubmit}>
            {/* email  */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email here"
                  required
                  onChange={handleChange}
                  className="block w-full rounded-sm border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6  outline-sky-700"
                />
              </div>
            </div>
            {/* password  */}
            <div>
              <div className="flex items-cE justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="www.google.com"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={handleChange}
                  placeholder="Enter your password here"
                  className="block w-full rounded-sm border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6 outline-sky-700"
                />
              </div>
            </div>
            {/* button  */}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 p-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
