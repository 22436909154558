import React from "react";
import Table from "../../components/table/Tables";
import Header from "../../components/header/Header";

export default function WeeklyReports () {

  return (
    <div className="w-10/12 px-5 overflow-auto h-screen reportPageContainer">
      <Header/>
      <Table type={"WEEK"}/>
    </div>
  );
}
