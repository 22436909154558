import { IoLogOutOutline } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { useContext } from "react";
import { MyContext } from "../../context";
import { image } from "../../constant";

function Sidebar() {
  const { setToken } = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <div
      className={`fixed flex flex-col bg-sky-900 h-screen w-1/6 border-r text-white`}
    >
      {/* section 1: rubico logo  */}
      <div className="flex items-center justify-center h-14 border-b my-5">
        <img
          className="mx-auto h-10 w-auto mobileViewLogo2"
          src={require(`../../assets/${image.RUBICO_FULL_L0G0}`)}
          alt="Your Company"
        />
        <img
          className="normalView mobileViewLogo mx-auto h-10 w-auto"
          src={require(`../../assets/${image.MOBILE_LOGO}`)}
          alt="Your Company"
        />
      </div>
      {/* section 2: tabs  */}
      <div className="overflow-y-auto overflow-x-hidden flex-grow">
        <ul className="flex flex-col py-4 space-y-1">
          {/* dashboard  */}
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-sm font-light tracking-wide text-white mobileView truncate">
                Dashboard
              </div>
            </div>
          </li>
          {/* weekly reports  */}
          <li>
            <Link
              to="/dashboard/weekly-reports"
              className={`relative flex flex-row items-center h-11 ${
                location.pathname === "/dashboard/weekly-reports"
                  ? "outline-none border-white bg-gray-500"
                  : ""
              } hover:bg-gray-700 border-l-4 border-transparent hover:border-white pr-6`}
            >
              <span className="inline-flex justify-center items-center ml-4">
                <TbReport />
              </span>

              <span className="ml-2 text-white tracking-wide truncate mobileView">
                Weekly Reports
              </span>
            </Link>
          </li>
          {/* monthly reports  */}
          <li>
            <Link
              to="/dashboard/monthly-reports"
              className={`relative flex flex-row items-center h-11 ${
                location.pathname === "/dashboard/monthly-reports"
                  ? "outline-none border-white bg-gray-500"
                  : ""
              } hover:bg-gray-700 border-l-4 border-transparent hover:border-white pr-6`}
            >
              <span className="inline-flex justify-center items-center ml-4">
                <TbReport />
              </span>

              <span className="ml-2 text-white tracking-wide truncate mobileView">
                Monthly Reports
              </span>
            </Link>
          </li>
        </ul>
      </div>
      {/* Logout section*/}
      <div
        className="relative flex flex-row items-center h-11 focus:outline-none focus:border-white focus:bg-gray-500 hover:bg-gray-700 border-l-4 border-transparent hover:border-white pr-6 mb-2"
        onClick={() => {
          localStorage.clear("userData");
          setToken("");
          navigate("/");
        }}
      >
        <span className="inline-flex justify-center items-center ml-4">
          <IoLogOutOutline />
        </span>

        <span className="ml-2 text-white tracking-wide truncate mobileView">
          Logout
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
