import { useState } from "react";
import { image } from "../../constant";

export default function Header() {

  return (
    <nav className="w-full flex justify-end items-center">
      <div className="py-5 flex items-center">
        <div>Rubico Inc.</div>
        <div
          className="rounded-full b-1 ml-2 overflow-hidden"
          style={{ border: "2px solid black" }}
        >
          <img
            src={require(`../../assets/${image.MOBILE_LOGO}`)}
            alt="loading"
            className="h-8 w-8"
          />
        </div>
      </div>
    </nav>
  );
}
