import React from "react";
import Table from "../../components/table/Tables";
import Header from "../../components/header/Header";
import '../../styles/globalStyle.css';

export default function MonthlyReports () {

  return (
    <div className="w-10/12 px-5 overflow-auto h-screen reportPageContainer">
      <Header/>
      <Table type={"MONTH"}/>
    </div>
  );
}
