import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { MyContext } from "./context";
import SignIn from "./pages/SignIn/SignIn";
import Dashboard from "./pages/dashboard/Dashboard";
import { route } from "./constant";

function App() {
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");
 
  return (
    <MyContext.Provider value={{ setToken, setRole, setUserId, token }}>
      <Routes>
        <Route path={route.ROOT} element={<SignIn />} />
        <Route path={route.DASHBOARD} element={<Dashboard />} />
      </Routes>
    </MyContext.Provider>
  );
}

export default App;
