export const route = {
  ROOT: "/",
  DASHBOARD: "/dashboard/*",
  WEEKLYREPORTS: "/weekly-reports",
  MONTHLYREPORTS: "/monthly-reports",
};

export const image = {
  RUBICO_FULL_L0G0: "rubico-white.png",
  MOBILE_LOGO: "rubico.png",
  RUBICO_SIGNIN_CARD_LOGO: "https://rubicotech.in/wp-content/uploads/2022/11/color-logo.svg",
};
