import { Route, Routes } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import MonthlyReports from "../monthlyReports/MonthlyReports";
import WeeklyReports from "../weeklyReports/WeeklyReports";
import { route } from "../../constant";

function Dashboard() {
  return (
    <div className="flex bg-gray-200 ">
      <Sidebar />
      <Routes>
        <Route path={route.WEEKLYREPORTS} element={<WeeklyReports/>} />
        <Route path={route.MONTHLYREPORTS} element={<MonthlyReports />} />
      </Routes>
    </div>
  );
}

export default Dashboard;
